
import Banner from '@/shared/Banner.vue';
import BreadcrumbWrapper from '@/shared/BreadcrumbWrapper.vue';
import { defineComponent } from 'vue';
import { useStore } from '@/store/index';

export default defineComponent({
  components: { Banner, BreadcrumbWrapper },
  setup() {
    const store = useStore();
    const company = store.state.company;

    return { company };
  }
});

<template>
  <div>
    <banner>
      <template v-slot:image>
        <img src="@/assets/images/cus_vs1.png" alt="" />
      </template>
      <template v-slot:content style="color: #fff">고객센터</template>
    </banner>
    <breadcrumb-wrapper></breadcrumb-wrapper>
    <div class="sub_container" style="padding: 50px 0 100px">
      <div class="sub_content">
        <h3>상담 및 서비스 절차 안내</h3>
        <div class="h3_txt">
          고객님의 의견에 대해 빠르고 정확한 처리를 약속 드립니다.<br />
          다양한 채널을 통해 접수된 고객님의 소중한 의견은 고객상담 시스템을 통해 신속하고 정성을 다해 응대 및 조치
          합니다.
        </div>
        <img src="@/assets/images/cus_img1.png" alt="" />
        <div class="cs_container">
          <ul>
            <li>
              <div class="tit">전화상담</div>
              <div class="tel_txt"><span></span>{{ company.primaryPhone }}</div>
              <div class="s_tit">운영시간</div>
              <div class="cs_time">
                <strong>평일 : 오전 09:00 ~ 오후 6:00 , 토요일 : 오전 09:00 ~ 오후 12:00</strong><br />
                * 일요일, 공휴일 휴무
              </div>
            </li>
            <li>
              <div class="tit">1:1 문의</div>
              <div class="qna_txt">
                궁금한 사항을 남겨주시면 <br />
                이메일을 통해 답변을 받으실 수 있습니다.
              </div>
              <a :href="`mailto:${company.email}`"> <strong>바로가기</strong> + </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Banner from '@/shared/Banner.vue';
import BreadcrumbWrapper from '@/shared/BreadcrumbWrapper.vue';
import { defineComponent } from 'vue';
import { useStore } from '@/store/index';

export default defineComponent({
  components: { Banner, BreadcrumbWrapper },
  setup() {
    const store = useStore();
    const company = store.state.company;

    return { company };
  }
});
</script>

<style scoped lang="scss">
@import './SupportView.scss';
</style>
